import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  .content-wrapper-alt {
    display: flex;
    box-shadow: 0px 32px 100px -12px rgba(0, 0, 0, 0.04);
    padding: 23px 26px;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 30px auto 0 auto;
    width: 474px;
    @media screen and (max-width: 568px) {
      padding: 16px 18px;
      margin: 12px auto 0 auto;
      width: 100%;
    }

    img {
      margin-right: 20px;
      @media screen and (max-width: 568px) {
        margin-right: 14px;
      }
    }

    p {
      font-size: ${themeGet("fontSizes.5")}px;

      @media only screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.2")}px;
      }
      @media only screen and (max-width: 568px) {
        font-size: ${themeGet("fontSizes.2")}px;
      }
    }
  }
`;

export const PartnershipWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media screen and (max-width: 568px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .content-wrapper {
    display: flex;
    box-shadow: 0px 32px 100px -12px rgba(0, 0, 0, 0.04);
    padding: 23px 26px;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 568px) {
      padding: 16px 18px;
    }

    img {
      margin-right: 20px;
      @media screen and (max-width: 568px) {
        margin-right: 14px;
      }
    }

    p {
      font-size: ${themeGet("fontSizes.5")}px;

      @media only screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.2")}px;
      }
      @media only screen and (max-width: 568px) {
        font-size: ${themeGet("fontSizes.2")}px;
      }
    }
  }
`;

export default SectionWrapper;
