import Container from "common/components/UI/Container";
import FeatureBlock from "common/components/FeatureBlock";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import SectionHeader from "common/components/SectionHeading";
import Text from "common/components/Text";
import React, { Fragment } from "react";
import { Icon } from "react-icons-kit";
import { graphql, useStaticQuery } from "gatsby";
import { mediaRecordOutline } from "react-icons-kit/typicons/mediaRecordOutline";
import { plus } from "react-icons-kit/typicons/plus";
import { starOutline } from "react-icons-kit/typicons/starOutline";

import SectionWrapper, { PartnershipWrapper } from "./partnership.style";

const Partnership = () => {
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        PARTNERSHIP_WHY_DO_IT_YOURSELF {
          title
          subtitle
          question
          items {
            color
            title
            text
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { title, subtitle, question, items } = data.ertCreditJson.PARTNERSHIP_WHY_DO_IT_YOURSELF;

  return (
    <SectionWrapper>
      <Container width="950px">
        <SectionHeader title={title} subtitle={subtitle} color="#69A2B1 " />
        <PartnershipWrapper>
          {items.map((item, index) => (
            <FeatureBlock
              key={`feature-key${index}`}
              style={{ "--color": `${item.color}` }}
              icon={
                <Fragment>
                  <Icon className="plus" icon={plus} />
                  <Icon className="circle" icon={mediaRecordOutline} />
                  <Image src={item.icon.publicURL} alt={item.title} />
                  <Icon className="star" icon={starOutline} />
                </Fragment>
              }
              iconPosition="left"
              title={<Heading as="h3" content={item.title} />}
              description={<Text content={item.text} />}
            />
          ))}
        </PartnershipWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Partnership;
