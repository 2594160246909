import Container from "common/components/UI/Container";
import Image from "common/components/Image";
import React from "react";
import SectionHeader from "common/components/SectionHeading";
import Text from "common/components/Text";
import { graphql, useStaticQuery } from "gatsby";

import SectionWrapper, { PartnershipWrapper } from "./whyPartnerWithUs.styles";

const WhyPartnerWithUs = () => {
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        PARTNERSHIP_WHY_PARTNER_ACCOUNTANT {
          title
          subtitle
          text
          items {
            text
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { title, subtitle, text, items } =
    data.ertCreditJson.PARTNERSHIP_WHY_PARTNER_ACCOUNTANT;

  const popped = items.pop();

  return (
    <SectionWrapper>
      <Container width="950px">
        <SectionHeader
          title={title}
          subtitle={subtitle}
          color="#69A2B1"
          text={text}
        />
        <PartnershipWrapper>
          {items.map((item, index) => {
            return (
              <div className="content-wrapper" key={index}>
                <Image src={item.icon.publicURL} alt="icon" />
                <Text content={item.text} />
              </div>
            );
          })}
        </PartnershipWrapper>
        <div className="content-wrapper-alt">
          <Image src={popped.icon.publicURL} alt="icon" />
          <Text content={popped.text} />
        </div>
        
      </Container>
    </SectionWrapper>
  );
};

export default WhyPartnerWithUs;
