import Box from "common/components/Box";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import React from "react";
import Text from "common/components/Text";
import { graphql, useStaticQuery } from "gatsby";

import { CustomerWrapper } from "./customers.style";

const Customers = ({partnerTodayClick}) => {
  const Data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        PARTNER_ITEMS {
          heading
          content
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          statistics {
            title
            text
          }
        }
      }
    }
  `);

  return (
    <CustomerWrapper>
      <Container width="1250px">
        <Box className="image">
          {Data.ertCreditJson.PARTNER_ITEMS.map((customerTrack, index) => (
            <GatsbyImage
              key={`customerTrack-image-${index}`}
              src={
                (customerTrack.image !== null) | undefined
                  ? customerTrack.image.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Track Chart"
            />
          ))}
        </Box>
        <Box className="content">
          {Data.ertCreditJson.PARTNER_ITEMS.map((customerTrack, index) => (
            <Box className="content" key={`customerTrack-${index}`}>
              <Heading as="h2" content={customerTrack.heading} />
              <Text
                as="p"
                content={customerTrack.content}
                className="description"
              />
            </Box>
          ))}
          <div className="statistics-wrapper">
            {Data.ertCreditJson.PARTNER_ITEMS[0].statistics.map(
              (statistic, index) => (
                <Box className="statistics" key={`statistics-${index}`}>
                  <Text
                    content={statistic.title}
                    className={
                      index !== 0
                        ? "statistics-header"
                        : "statistics-header-main"
                    }
                  />
                  <Text content={statistic.text} className="statistics-text" />
                </Box>
              )
            )}
          </div>
          <Button
            variant="textButton"
            title="Become A Partner Today"
            onClick={partnerTodayClick}
          />
        </Box>
      </Container>
    </CustomerWrapper>
  );
};

export default Customers;
