import * as React from "react";
import HeroAlt from "containers/HeroAlt";
import Layout from "common/components/Layout";
import LetsConnect from "containers/LetsConnect";
import Seo from "components/seo";
import WhyPartnerWithUsAccountant from "containers/WhyPartnerWithUsAccountant";
import WhyDoYourself from "containers/WhyDoYourself";
import Button from "common/components/Button";
import styled from "styled-components";
import WhatOtherPartnersAreSaying from "containers/WhatOtherPartnersAreSaying";

const PartnerTodayButton = styled(Button)`
  margin: 0 auto;
  display: block;
  padding: 10px;
  margin-top: 20px;
`;

const AccountingPartnershipsPage = ({ location }) => {
  const formRef = React.createRef();

  const handlePartnerButtonClick = () => {
    formRef.current.scrollIntoView();
  }

  return (
    <Layout location={location.pathname}>
      <Seo title="Accountants Love Partnering With Us" />
      <HeroAlt
        title="Financial Advisors"
        text="Financial Advisors and their firms are valued partners because you have earned your clients trust over a long period of time. Our goal is to help you deepen this trusted relationship by providing your clients with the best possible experience and highest return on investment."
      />
      <WhyPartnerWithUsAccountant />
      <div>
        <PartnerTodayButton
          title="Become a Partner Today"
          variant="textButton"
          onClick={handlePartnerButtonClick}
          className="learn-btn"
        />
      </div>
      <WhyDoYourself />
      <div>
        <PartnerTodayButton
          title="Become a Partner Today"
          variant="textButton"
          onClick={handlePartnerButtonClick}
          className="learn-btn"
        />
      </div>
      <WhatOtherPartnersAreSaying partnerTodayClick={handlePartnerButtonClick} />
      <div ref={formRef}>
        <LetsConnect />
      </div>
    </Layout>
  );
};

export default AccountingPartnershipsPage;
