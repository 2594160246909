import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const CustomerWrapper = styled.section`
  position: relative;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    @media screen and (max-width: 768px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 568px) {
      flex-direction: column;
      margin-bottom: 0px;
    }

    .image {
      width: 670px;
      margin-left: -60px;
      @media screen and (max-width: 990px) {
        margin-left: -30px;
      }
      @media screen and (max-width: 568px) {
        width: 120%;
      }
    }
  }

  .content {
    max-width: 540px;
    margin-top: -30px;
    @media screen and (max-width: 840px) {
      text-align: center;
      margin-top: 0px;
    }
    h2 {
      font-size: ${themeGet("fontSizes.13")}px;
      line-height: 50px;
      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.10")}px;
        line-height: 36px;
      }

      @media screen and (max-width: 568px) {
        text-align: center;
        margin-top: -55px;
      }
    }
    .description {
      max-width: 440px;
      margin-bottom: 30px;
      line-height: 28px;
      font-size: ${themeGet("fontSizes.5")}px;
      margin-top: 10px;
      @media screen and (max-width: 990px) {
        margin-top: 6px;
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
      }
      @media screen and (max-width: 568px) {
        text-align: center;
      }
    }
  }

  .statistics-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 70px;
    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 568px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .statistics {
    display: flex;
    flex-direction: column;
  }

  .statistics-header {
    font-family: "Poppins";
    font-weight: 600;
    letter-spacing: -0.5px;
    font-size: ${themeGet("fontSizes.5")}px;
    margin-bottom: 0px !important;
    @media screen and (max-width: 990px) {
      font-size: ${themeGet("fontSizes.2")}px;
      line-heioght: 22px;
    }
  }

  .statistics-header-main {
    font-family: "Poppins";
    font-weight: 600;
    letter-spacing: -0.5px;
    font-size: ${themeGet("fontSizes.11")}px;
    margin-bottom: 0px !important;
    color: #1e88a4;
    @media screen and (max-width: 990px) {
      font-size: ${themeGet("fontSizes.8")}px;
      line-heioght: 22px;
    }
  }

  .statistics-text {
    margin-bottom: 0px !important;
    color: #757a7e;
  }

  .reusecore__button {
    width: 95%;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
      width: 100%;
      font-size: 16px;
    }
  }
`;
